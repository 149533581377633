import {
	AiFillSafetyCertificate,
	AiOutlineMessage,
	AiOutlineQrcode,
} from 'react-icons/ai';
import { FaSnapchatGhost, FaSpotify } from 'react-icons/fa';
import { MdMessage, MdQueueMusic, MdReportProblem } from 'react-icons/md';
import React, { useEffect, useRef, useState } from 'react';
import { animated, config, useSpring } from 'react-spring';

import { BsFillPeopleFill } from 'react-icons/bs';
import { GiSiren } from 'react-icons/gi';
import { HiUserGroup } from 'react-icons/hi';
import { SiVenmo } from 'react-icons/si';
import iphoneOutline from './assets/iphoneOutline.png';

const AboutBody = () => {
	const [index, setIndex] = useState(0);
	const timeoutRef = useRef(null);
	const [featureIndex, setFeatureIndex] = useState(0);
	const [iconToggle, setIconToggle] = useState(false);

	const colors = [FaSpotify, SiVenmo, FaSnapchatGhost];
	const delay = 5000;

	function resetTimeout() {
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
		}
	}

	const iconArray = [FaSpotify, SiVenmo, FaSnapchatGhost];

	const IconAnimation = useSpring({
		to: { opacity: 1 },
		from: { opacity: 0 },
		reset: true,
		config: config.molasses,
	});

	useEffect(() => {
		resetTimeout();
		timeoutRef.current = setTimeout(
			() =>
				setIndex((prevIndex) =>
					prevIndex === colors.length - 1 ? 0 : prevIndex + 1
				),
			delay
		);

		return () => {
			resetTimeout();
		};
	}, [index]);

	const featureIconStyle = 'text-otto-blue text-5xl';
	const featureTextStyle = 'text-white font-semibold mt-1 text-center';

	const featureStyleArray = [
		'top-0 -left-11',
		'top-20 -left-11',
		'bottom-24 -left-11',
		'-bottom-0.5 -left-11',
		'top-0 -right-11',
		'top-24 -right-11',
		'bottom-20 -right-11',
		'-bottom-0.5 -right-11',
	];

	const featureText = [
		{
			header: 'Spotify',
			message:
				'We have a fully integrated Spotify client that allows for seamless music engagement and crowd sourced fun.',
			icon: <FaSpotify />,
		},
		{
			header: 'Venmo',
			message:
				'We are working to create a Venmo workflow that allows for accurate payment tracking with unique technologies that will allow users to keep accurate balances and manage their events financial balances with ease.',
			icon: <SiVenmo />,
		},
		{
			header: 'Safety',
			message:
				'By using a series of location technologies and advanced algorithms we are working to be able to predict and prevent risky situations before they even begin.',
			icon: <AiFillSafetyCertificate />,
		},
		{
			header: 'Smart Alerts',
			message:
				'Our unique safety algorithms are complimented by a smart alert system that allows hosts and individuals to communicate and be more aware of their surroundings. The goal of these alerts is to make sure as much information is available as quickly as possible.',
			icon: <GiSiren />,
		},
		{
			header: 'Emergency Guides',
			message:
				'Otto will feature a series of emergency protocol that come with clear steps on how to handle minor situations to full blown emergencies. The guides will be advised by experts and tailored specifically to provide clear steps with immediate action.',
			icon: <MdReportProblem />,
		},
		{
			header: 'Smart Alerts',
			message:
				'Our unique safety algorithms are complimented by a smart alert system that allows hosts and individuals to communicate and be more aware of their surroundings. The goal of these alerts is to make sure as much information is available as quickly as possible.',
			icon: <GiSiren />,
		},
		{
			header: 'Smart Alerts',
			message:
				'Our unique safety algorithms are complimented by a smart alert system that allows hosts and individuals to communicate and be more aware of their surroundings. The goal of these alerts is to make sure as much information is available as quickly as possible.',
			icon: <GiSiren />,
		},
		{
			header: 'Smart Alerts',
			message:
				'Our unique safety algorithms are complimented by a smart alert system that allows hosts and individuals to communicate and be more aware of their surroundings. The goal of these alerts is to make sure as much information is available as quickly as possible.',
			icon: <GiSiren />,
		},
	];

	return (
		<div className='fr-center-full justify-evenly my-10'>
			<div className='h-full w-1/2 fc-center-full'>
				<div className='text-white text-center -mt-20 font-bold text-3xl'>
					Features to <span className='underline text-otto-blue'>enhance</span>{' '}
					your party experience
				</div>
				<div className='fr-center-center-full mt-4'>
					<div className='fc-center'>
						<div
							onClick={() => {
								setFeatureIndex(0);
							}}
							className='fc-center'>
							<MdQueueMusic className={featureIconStyle} />
							<div className={featureTextStyle}>Queue a Song</div>
						</div>
						<div
							onClick={() => {
								setFeatureIndex(1);
							}}
							className='fc-center mt-4'>
							<GiSiren className={featureIconStyle} />
							<div className={featureTextStyle}>Text Alert System</div>
						</div>
						<div
							onClick={() => {
								setFeatureIndex(2);
							}}
							className='fc-center mt-4'>
							<BsFillPeopleFill className={featureIconStyle} />
							<div className={featureTextStyle}>Guest Lists</div>
						</div>
						<div
							onClick={() => {
								setFeatureIndex(3);
							}}
							className='fc-center mt-4'>
							<AiOutlineQrcode className={featureIconStyle} />
							<div className={featureTextStyle}>QR Validation</div>
						</div>
					</div>
					<div
						className={`hidden sm:flex md:w-56 md:h-80 bg-white rounded-lg shadow-lg fc-center-full overflow-y-scroll ${
							featureIndex === 0
								? 'rounded-tl-none'
								: featureIndex === 3
								? 'rounded-bl-none'
								: featureIndex === 4
								? 'rounded-tr-none'
								: featureIndex === 7
								? 'rounded-br-none'
								: null
						} mx-12 relative`}>
						<div
							class={`w-11 absolute ${featureStyleArray[featureIndex]} overflow-hidden hidden md:inline-block`}>
							<div
								class={`h-16 bg-white transform ${
									featureIndex <= 3
										? '-rotate-45 origin-top-right'
										: 'rotate-45 origin-top-left'
								}`}></div>
						</div>
						<div className='fc-center-full'>
							<div className='text-2xl mt-2'>
								{featureText[featureIndex].icon}
							</div>
							<div className='text-lg text-center'>
								{featureText[featureIndex].header}
							</div>
							<div className='text-center text-sm text-gray-500 px-2 mt-1'>
								{featureText[featureIndex].message}
							</div>
						</div>
					</div>
					<div className='fc-center justify-evenly ml-4 sm:ml-0'>
						<div
							onClick={() => {
								setFeatureIndex(4);
							}}
							className='fc-center'>
							<HiUserGroup className={featureIconStyle} />
							<div className={featureTextStyle}>Group Hosting</div>
						</div>
						<div
							onClick={() => {
								setFeatureIndex(5);
							}}
							className='fc-center mt-4'>
							<MdReportProblem className={featureIconStyle} />
							<div className={featureTextStyle}>Problem Reporting</div>
						</div>
						<div
							onClick={() => {
								setFeatureIndex(6);
							}}
							className='fc-center mt-4'>
							<MdMessage className={featureIconStyle} />
							<div className={featureTextStyle}>In-App Messaging</div>
						</div>
						<div
							onClick={() => {
								setFeatureIndex(7);
							}}
							className='fc-center mt-4'>
							<MdQueueMusic className={featureIconStyle} />
							<div className={featureTextStyle}>NEED ONE MORE</div>
						</div>
					</div>
				</div>
			</div>
			<div className='hidden headerButtonWidth:flex flex-col items-center justify-center relative w-1/2'>
				<img src={iphoneOutline} />
				<div className='absolute top-24 mx-auto left-0 right-0 w-72 bg-otto-blue rounded-lg overflow-hidden'>
					<div className='fc-center-full justify-around'>
						<div className='text-white text-8xl mt-3 line-through'>OTTO</div>
						<animated.div style={IconAnimation}>
							{index === 0 ? (
								<FaSpotify
									className={`text-otto-green text-7xl mt-14 transition duration-200 ease ${
										index === 0 ? 'opacity-100' : 'opacity-0'
									}`}
								/>
							) : null}
							{index === 1 ? (
								<SiVenmo
									className={`text-blue-400 text-8xl mt-8 pt-2 transition duration-200 ease ${
										index === 1 ? 'opacity-100' : 'opacity-0'
									}`}
								/>
							) : null}
							{index === 2 ? (
								<FaSnapchatGhost
									className={`text-yellow-400 text-7xl mt-14 transition duration-200 ease ${
										index === 2 ? 'opacity-100' : 'opacity-0'
									}`}
								/>
							) : null}
						</animated.div>
						<div className='my-28 pt-7 w-full text-white text-center text-xl font-light opacity-0'>
							Unique Party Integrations
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AboutBody;
