import './App.css';
import './styles/main.css';

import {
	Redirect,
	Route,
	BrowserRouter as Router,
	Switch,
} from 'react-router-dom';

import LandingPage from './pages/LandingPage';
import logo from './logo.svg';

function App() {
	return (
		<Router>
			<Route exact path='/' page={'LandingPage'} component={LandingPage} />
			<Redirect to='/' /> {/* Path not matched */}
		</Router>
	);
}

export default App;
