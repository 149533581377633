import {
	AiOutlineCheck,
	AiOutlineExclamation,
	AiOutlineLink,
} from 'react-icons/ai';
import React, { useEffect, useRef, useState } from 'react';

import AboutBody from '../components/AboutBody';
import { GoMailRead } from 'react-icons/go';
import HeaderBar from '../components/HeaderBar';
import { Link } from 'react-scroll';
import Particles from 'react-particles-js';
import { useForm } from 'react-hook-form';

const LandingPage = () => {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();
	const onSubmit = (data) => console.log(data);
	console.log(errors);

	const featureBoxStyle =
		'bg-otto-green bg-opacity-75 py-4 px-2 rounded-lg fr-center justify-between w-1/4 hover:bg-opacity-100 hover-scale-105 group flex-grow mx-2';

	const contactInputStyle =
		'w-2/3 md:w-1/3 border-b border-otto-grey my-3 p-2 bg-transparent focus:outline-none focus:border-otto-green text-white';

	return (
		<div
			id='home'
			className='w-full h-full bg-otto-blue absolute text-black fc-center-full absolute'>
			<HeaderBar />
			<div className='fc-center w-3/4 relative z-10'>
				<div className='text-otto-green text-5xl mt-4 sm:mt-36 w-full text-center'>
					Together, we can make social events streamlined and safer.
				</div>
				<div className='hidden sm:block text-otto-grey text-xl mt-3 w-full text-center'>
					Otto seeks to help people manage events and provide vital safety
					features that can save lives and prevent assaults
				</div>
				<div className='hidden headerButtonWidth:flex flex-row w-1/2 headerButtonWidth:w-2/3 items-center mt-12 justify-around'>
					<div className={featureBoxStyle}>
						<div className='font-medium text-black text-medium'>
							Smart Alerts
						</div>

						<AiOutlineExclamation className='flex-shrink-0 h-7 w-7 animate-pulse text-otto-green rounded-full text-3xl bg-gray-500 p-1' />
					</div>
					<div className={featureBoxStyle}>
						<div className='font-medium text-black text-medium'>
							Safety Features
						</div>
						<AiOutlineCheck className='flex-shrink-0 h-7 w-7 animate-pulse text-otto-green rounded-full text-3xl bg-gray-500 p-1' />
					</div>
					<div className={featureBoxStyle}>
						<div className='font-medium text-black text-medium'>
							Social Integrations
						</div>
						<AiOutlineLink className='flex-shrink-0 h-7 w-7 animate-pulse text-otto-green rounded-full text-3xl bg-gray-500 p-1' />
					</div>
				</div>
				<div className='fr-center-center justify-center w-9/12 md:w-7/12 mt-12'>
					<input
						className='bg-white w-full md:w-3/5 h-12 md:h-16 p-4 rounded-full md:rounded-r-none focus:outline-none'
						type='text'
						placeholder='Your email address'
					/>
					<div className='hidden md:flex w-2/5 h-16 bg-otto-grey p-4 rounded-r-full text-center fc-center-center font-semibold text-otto-blue cursor-pointer hover:bg-gray-400 transition duration-200 ease-in-out'>
						Join the Waitlist
					</div>
				</div>
				<div className='md:hidden bg-otto-grey p-1 px-6 mt-3 rounded-full text-center fc-center-center font-semibold text-otto-blue cursor-pointer hover:bg-gray-400 transition duration-200 ease-in-out'>
					Submit
				</div>
			</div>
			<div className='absolute top-3/4 left-0 w-full overflow-hidden leading-none bg-otto-blue pb-44'>
				<svg
					className='transform rotate-180'
					data-name='Layer 1'
					xmlns='http://www.w3.org/2000/svg'
					viewBox='0 0 1200 120'
					preserveAspectRatio='none'>
					<path
						d='M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z'
						opacity='.25'
						class='fill-current text-otto-green'></path>
					<path
						d='M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z'
						opacity='.5'
						class='fill-current text-otto-green'></path>
					<path
						d='M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z'
						class='fill-current text-otto-green'></path>
				</svg>
				<div
					id='about'
					className='fc-center-full relative z-30 bg-otto-green py-20 headerButtonWidth:py-0'>
					<div className='fr-center-center-full'>
						<AboutBody />
					</div>
				</div>
				<svg
					className=''
					data-name='Layer 1'
					xmlns='http://www.w3.org/2000/svg'
					viewBox='0 0 1200 120'
					preserveAspectRatio='none'>
					<path
						d='M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z'
						opacity='.25'
						class='fill-current text-otto-green'></path>
					<path
						d='M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z'
						opacity='.5'
						class='fill-current text-otto-green'></path>
					<path
						d='M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z'
						class='fill-current text-otto-green'></path>
				</svg>

				<div className='w-full relative z-10 h-60 mt-4' id='contact'>
					<div className='font-bold text-white text-3xl w-full text-center'>
						HAVE ANY QUESTIONS?
					</div>
					<div className='text-otto-grey mt-1 w-full text-center'>
						Reach out to us below.
					</div>
					<div className='fr-center-center-full'>
						<div className='flex-grow'>
							<form
								className='fc-center-center'
								onSubmit={handleSubmit(onSubmit)}>
								<input
									className={contactInputStyle}
									type='text'
									placeholder='First Name'
									{...register('First Name', { required: true, maxLength: 80 })}
								/>
								<input
									className={contactInputStyle}
									type='text'
									placeholder='Last Name'
									{...register('Last Name', { required: true, maxLength: 100 })}
								/>
								<input
									className={contactInputStyle}
									type='text'
									placeholder='Email'
									{...register('Email', {
										required: true,
										pattern: /^\S+@\S+$/i,
									})}
								/>
								<textarea
									className='w-2/3 md:w-1/3 border resize-none leading-normal border-otto-grey my-3 pt-2 pb-1.5 px-2 bg-transparent focus:outline-none focus:border-otto-green text-white'
									placeholder='Message'
									{...register('Message', { required: true, maxLength: 500 })}
								/>

								<input
									className='border bg-transparent text-otto-green text-xl border-otto-green p-2 px-6 mt-2 rounded-full transition duration-300 ease-in-out hover:bg-otto-green hover:text-white cursor-pointer'
									type='submit'
								/>
							</form>
						</div>
					</div>
				</div>
			</div>
			<Particles
				className='fixed w-full h-full z-0'
				params={{
					particles: {
						number: {
							value: 355,
							density: {
								enable: true,
								value_area: 789.1476416322727,
							},
						},
						color: {
							value: '#ffffff',
						},
						shape: {
							type: 'circle',
							stroke: {
								width: 0,
								color: '#000000',
							},
							polygon: {
								nb_sides: 5,
							},
							image: {
								src: 'img/github.svg',
								width: 100,
								height: 100,
							},
						},
						opacity: {
							value: 0.48927153781200905,
							random: false,
							anim: {
								enable: true,
								speed: 0.2,
								opacity_min: 0,
								sync: false,
							},
						},
						size: {
							value: 2,
							random: true,
							anim: {
								enable: true,
								speed: 2,
								size_min: 0,
								sync: false,
							},
						},
						line_linked: {
							enable: false,
							distance: 20,
							color: '#ffffff',
							opacity: 0.4,
							width: 1,
						},
						move: {
							enable: true,
							speed: 0.2,
							direction: 'none',
							random: true,
							straight: false,
							out_mode: 'out',
							bounce: false,
							attract: {
								enable: false,
								rotateX: 600,
								rotateY: 1200,
							},
						},
					},
					interactivity: {
						detect_on: 'canvas',
						events: {
							onhover: {
								enable: true,
								mode: 'bubble',
							},
							onclick: {
								enable: true,
								mode: 'push',
							},
							resize: true,
						},
						modes: {
							grab: {
								distance: 400,
								line_linked: {
									opacity: 1,
								},
							},
							bubble: {
								distance: 83.91608391608392,
								size: 2,
								duration: 3,
								opacity: 1,
								speed: 3,
							},
							repulse: {
								distance: 200,
								duration: 0.4,
							},
							push: {
								particles_nb: 6,
							},
							remove: {
								particles_nb: 2,
							},
						},
					},
					retina_detect: true,
				}}
			/>
		</div>
	);
};

export default LandingPage;
