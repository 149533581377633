import { Link } from 'react-scroll';
import React from 'react';

const HeaderBar = () => {
	const headerSubMenuTextStyle =
		'hidden sm:block text-white mx-7 text-lg font-semibold cursor-pointer hover:underline hover-scale-105';

	return (
		<div className='fr-center-full justify-between p-4 z-20 relative'>
			<div className='text-white text-5xl line-through cursor-default hover-scale-110'>
				OTTO
			</div>
			<div className='fr-center'>
				<div className={headerSubMenuTextStyle}>
					<Link to='home' spy={true} smooth={true}>
						Home
					</Link>
				</div>
				<div className={headerSubMenuTextStyle}>
					<Link to='about' spy={true} smooth={true}>
						About
					</Link>
				</div>
				<div className={headerSubMenuTextStyle}>
					<Link to='contact' spy={true} smooth={true}>
						Contact
					</Link>
				</div>
				<div className='bg-otto-green bg-opacity-75 hover:bg-opacity-100 hover-scale-105 cursor-pointer px-4 py-2 rounded-lg font-semibold'>
					Sign Up
				</div>
			</div>
		</div>
	);
};

export default HeaderBar;
